$icomoon-font-family: "stmicons" !default;
$icomoon-font-path: "fonts" !default;

$stmicon-traffic: "\10fbbf";
$stmicon-queue_music: "\10fbc0";
$stmicon-public: "\10fbc1";
$stmicon-perm_camera_mic: "\10fbc2";
$stmicon-music_video: "\10fbc3";
$stmicon-music_off: "\10fbc4";
$stmicon-music_note: "\10fbc5";
$stmicon-mic_off: "\10fbc6";
$stmicon-mic_none: "\10fbc7";
$stmicon-mic: "\10fbc8";
$stmicon-library_music: "\10fbc9";
$stmicon-headset_mic: "\10fbca";
$stmicon-graphic_eq: "\10fbcb";
$stmicon-format_italic: "\10fbcc";
$stmicon-file_copy: "\10fbcd";
$stmicon-screen_lock_portrait: "\10fbce";
$stmicon-restaurant_menu: "\10fbcf";
$stmicon-power: "\10fbd0";
$stmicon-hot_tub: "\10fbd1";
$stmicon-zoom_out_map: "\10fbd2";
$stmicon-zoom_out: "\10fbd3";
$stmicon-zoom_in: "\10fbd4";
$stmicon-wrap_text: "\10fbd5";
$stmicon-whatshot: "\10fbd6";
$stmicon-wifi_lock: "\10fbd7";
$stmicon-wifi_tethering: "\10fbd8";
$stmicon-wifi: "\10fbd9";
$stmicon-work: "\10fbda";
$stmicon-youtube_searched_for: "\10fbdb";
$stmicon-weekend: "\10fbdc";
$stmicon-widgets: "\10fbdd";
$stmicon-web_asset: "\10fbde";
$stmicon-web: "\10fbdf";
$stmicon-wb_incandescent: "\10fbe0";
$stmicon-wb_iridescent: "\10fbe1";
$stmicon-wb_sunny: "\10fbe2";
$stmicon-wc: "\10fbe3";
$stmicon-watch: "\10fbe4";
$stmicon-wb_cloudy: "\10fbe5";
$stmicon-wallpaper: "\10fbe6";
$stmicon-watch_later: "\10fbe7";
$stmicon-wb_auto: "\10fbe8";
$stmicon-vpn_lock: "\10fbe9";
$stmicon-warning: "\10fbea";
$stmicon-volume_off: "\10fbeb";
$stmicon-volume_up: "\10fbec";
$stmicon-vpn_key: "\10fbed";
$stmicon-visibility: "\10fbee";
$stmicon-voice_chat: "\10fbef";
$stmicon-voicemail: "\10fbf0";
$stmicon-volume_down: "\10fbf1";
$stmicon-volume_mute: "\10fbf2";
$stmicon-vignette: "\10fbf3";
$stmicon-visibility_off: "\10fbf4";
$stmicon-view_week: "\10fbf5";
$stmicon-view_quilt: "\10fbf6";
$stmicon-view_stream: "\10fbf7";
$stmicon-view_day: "\10fbf8";
$stmicon-view_headline: "\10fbf9";
$stmicon-view_list: "\10fbfa";
$stmicon-view_module: "\10fbfb";
$stmicon-view_comfy: "\10fbfc";
$stmicon-view_compact: "\10fbfd";
$stmicon-view_carousel: "\10fbfe";
$stmicon-videocam: "\10fbff";
$stmicon-view_agenda: "\10fc00";
$stmicon-view_array: "\10fc01";
$stmicon-view_column: "\10fc02";
$stmicon-videogame_asset: "\10fc03";
$stmicon-video_call: "\10fc04";
$stmicon-video_label: "\10fc05";
$stmicon-video_library: "\10fc06";
$stmicon-videocam_off: "\10fc07";
$stmicon-vertical_align_top: "\10fc08";
$stmicon-vertical_align_bottom: "\10fc09";
$stmicon-vertical_align_center: "\10fc0a";
$stmicon-vibration: "\10fc0b";
$stmicon-usb: "\10fc0c";
$stmicon-verified_user: "\10fc0d";
$stmicon-unfold_less: "\10fc0e";
$stmicon-update: "\10fc0f";
$stmicon-unarchive: "\10fc10";
$stmicon-undo: "\10fc11";
$stmicon-unfold_more: "\10fc12";
$stmicon-turned_in_not: "\10fc13";
$stmicon-turned_in: "\10fc14";
$stmicon-tv: "\10fc15";
$stmicon-trending_down: "\10fc16";
$stmicon-tune: "\10fc17";
$stmicon-trending_flat: "\10fc18";
$stmicon-trending_up: "\10fc19";
$stmicon-transform: "\10fc1a";
$stmicon-translate: "\10fc1b";
$stmicon-tram: "\10fc1c";
$stmicon-transfer_within_a_station: "\10fc1d";
$stmicon-touch_app: "\10fc1e";
$stmicon-toys: "\10fc1f";
$stmicon-track_changes: "\10fc20";
$stmicon-train: "\10fc21";
$stmicon-toll: "\10fc22";
$stmicon-tonality: "\10fc23";
$stmicon-timer_3: "\10fc24";
$stmicon-today: "\10fc25";
$stmicon-title: "\10fc26";
$stmicon-toc: "\10fc27";
$stmicon-thumbs_up_down: "\10fc28";
$stmicon-timer_10: "\10fc29";
$stmicon-timer_off: "\10fc2a";
$stmicon-timer: "\10fc2b";
$stmicon-timelapse: "\10fc2c";
$stmicon-timeline: "\10fc2d";
$stmicon-thumb_down: "\10fc2e";
$stmicon-thumb_up: "\10fc2f";
$stmicon-time_to_leave: "\10fc30";
$stmicon-textsms: "\10fc31";
$stmicon-theaters: "\10fc32";
$stmicon-text_format: "\10fc33";
$stmicon-texture: "\10fc34";
$stmicon-switch_video: "\10fc35";
$stmicon-text_fields: "\10fc36";
$stmicon-terrain: "\10fc37";
$stmicon-tab: "\10fc38";
$stmicon-tablet: "\10fc39";
$stmicon-tag_faces: "\10fc3a";
$stmicon-tap_and_play: "\10fc3b";
$stmicon-sync_problem: "\10fc3c";
$stmicon-tablet_android: "\10fc3d";
$stmicon-tablet_mac: "\10fc3e";
$stmicon-system_update: "\10fc3f";
$stmicon-tab_unselected: "\10fc40";
$stmicon-sync: "\10fc41";
$stmicon-sync_disabled: "\10fc42";
$stmicon-supervisor_account: "\10fc43";
$stmicon-swap_vertical_circle: "\10fc44";
$stmicon-switch_camera: "\10fc45";
$stmicon-swap_horiz: "\10fc46";
$stmicon-swap_vert: "\10fc47";
$stmicon-surround_sound: "\10fc48";
$stmicon-swap_calls: "\10fc49";
$stmicon-subscriptions: "\10fc4a";
$stmicon-subtitles: "\10fc4b";
$stmicon-subway: "\10fc4c";
$stmicon-subject: "\10fc4d";
$stmicon-subdirectory_arrow_right: "\10fc4e";
$stmicon-store: "\10fc4f";
$stmicon-strikethrough_s: "\10fc50";
$stmicon-style: "\10fc51";
$stmicon-subdirectory_arrow_left: "\10fc52";
$stmicon-storage: "\10fc53";
$stmicon-store_mall_directory: "\10fc54";
$stmicon-straighten: "\10fc55";
$stmicon-streetview: "\10fc56";
$stmicon-stop: "\10fc57";
$stmicon-stay_primary_portrait: "\10fc58";
$stmicon-stop_screen_share: "\10fc59";
$stmicon-stay_primary_landscape: "\10fc5a";
$stmicon-star: "\10fc5b";
$stmicon-stay_current_portrait: "\10fc5c";
$stmicon-spellcheck: "\10fc5d";
$stmicon-star_half: "\10fc5e";
$stmicon-stars: "\10fc5f";
$stmicon-stay_current_landscape: "\10fc60";
$stmicon-speaker: "\10fc61";
$stmicon-star_border: "\10fc62";
$stmicon-speaker_notes: "\10fc63";
$stmicon-speaker_phone: "\10fc64";
$stmicon-speaker_notes_off: "\10fc65";
$stmicon-spa: "\10fc66";
$stmicon-space_bar: "\10fc67";
$stmicon-speaker_group: "\10fc68";
$stmicon-sort: "\10fc69";
$stmicon-sms_failed: "\10fc6a";
$stmicon-sms: "\10fc6b";
$stmicon-snooze: "\10fc6c";
$stmicon-sort_by_alpha: "\10fc6d";
$stmicon-smoke_free: "\10fc6e";
$stmicon-smoking_rooms: "\10fc6f";
$stmicon-slow_motion_video: "\10fc70";
$stmicon-smartphone: "\10fc71";
$stmicon-skip_previous: "\10fc72";
$stmicon-slideshow: "\10fc73";
$stmicon-sim_card: "\10fc74";
$stmicon-skip_next: "\10fc75";
$stmicon-signal_wifi_4_bar: "\10fc76";
$stmicon-signal_wifi_off: "\10fc77";
$stmicon-signal_wifi_3_bar-path1: "\10fc78";
$stmicon-signal_wifi_3_bar-path2: "\10fc79";
$stmicon-signal_wifi_4_bar_lock: "\10fc7a";
$stmicon-signal_wifi_2_bar_lock-path1: "\10fc7b";
$stmicon-signal_wifi_2_bar_lock-path2: "\10fc7c";
$stmicon-signal_wifi_2_bar_lock-path3: "\10fc7d";
$stmicon-signal_wifi_2_bar-path1: "\10fc7e";
$stmicon-signal_wifi_2_bar-path2: "\10fc7f";
$stmicon-signal_wifi_3_bar_lock-path1: "\10fc80";
$stmicon-signal_wifi_3_bar_lock-path2: "\10fc81";
$stmicon-signal_wifi_3_bar_lock-path3: "\10fc82";
$stmicon-signal_wifi_0_bar: "\10fc83";
$stmicon-signal_wifi_1_bar_lock-path1: "\10fc84";
$stmicon-signal_wifi_1_bar_lock-path2: "\10fc85";
$stmicon-signal_wifi_1_bar_lock-path3: "\10fc86";
$stmicon-signal_wifi_1_bar-path1: "\10fc87";
$stmicon-signal_wifi_1_bar-path2: "\10fc88";
$stmicon-signal_cellular_no_sim: "\10fc89";
$stmicon-signal_cellular_off: "\10fc8a";
$stmicon-signal_cellular_connected_no_internet_4_bar: "\10fc8b";
$stmicon-signal_cellular_null: "\10fc8c";
$stmicon-signal_cellular_connected_no_internet_2_bar-path1: "\10fc8d";
$stmicon-signal_cellular_connected_no_internet_2_bar-path2: "\10fc8e";
$stmicon-signal_cellular_connected_no_internet_3_bar-path1: "\10fc8f";
$stmicon-signal_cellular_connected_no_internet_3_bar-path2: "\10fc90";
$stmicon-signal_cellular_connected_no_internet_0_bar-path1: "\10fc91";
$stmicon-signal_cellular_connected_no_internet_0_bar-path2: "\10fc92";
$stmicon-signal_cellular_connected_no_internet_1_bar-path1: "\10fc93";
$stmicon-signal_cellular_connected_no_internet_1_bar-path2: "\10fc94";
$stmicon-signal_cellular_1_bar-path1: "\10fc95";
$stmicon-signal_cellular_1_bar-path2: "\10fc96";
$stmicon-signal_cellular_2_bar-path1: "\10fc97";
$stmicon-signal_cellular_2_bar-path2: "\10fc98";
$stmicon-signal_cellular_3_bar-path1: "\10fc99";
$stmicon-signal_cellular_3_bar-path2: "\10fc9a";
$stmicon-signal_cellular_4_bar: "\10fc9b";
$stmicon-show_chart: "\10fc9c";
$stmicon-shuffle: "\10fc9d";
$stmicon-short_text: "\10fc9e";
$stmicon-signal_cellular_0_bar: "\10fc9f";
$stmicon-shop: "\10fca0";
$stmicon-shopping_basket: "\10fca1";
$stmicon-shopping_cart: "\10fca2";
$stmicon-settings_voice: "\10fca3";
$stmicon-share: "\10fca4";
$stmicon-shop_two: "\10fca5";
$stmicon-settings: "\10fca6";
$stmicon-settings_phone: "\10fca7";
$stmicon-settings_power: "\10fca8";
$stmicon-settings_remote: "\10fca9";
$stmicon-settings_system_daydream: "\10fcaa";
$stmicon-settings_input_hdmi: "\10fcab";
$stmicon-settings_input_svideo: "\10fcac";
$stmicon-settings_overscan: "\10fcad";
$stmicon-settings_input_composite: "\10fcae";
$stmicon-settings_input_component: "\10fcaf";
$stmicon-settings_bluetooth: "\10fcb0";
$stmicon-settings_brightness: "\10fcb1";
$stmicon-settings_cell: "\10fcb2";
$stmicon-settings_ethernet: "\10fcb3";
$stmicon-settings_input_antenna: "\10fcb4";
$stmicon-settings_backup_restore: "\10fcb5";
$stmicon-settings_applications: "\10fcb6";
$stmicon-security: "\10fcb7";
$stmicon-sentiment_satisfied: "\10fcb8";
$stmicon-sentiment_very_dissatisfied: "\10fcb9";
$stmicon-sentiment_very_satisfied: "\10fcba";
$stmicon-sentiment_dissatisfied: "\10fcbb";
$stmicon-sentiment_neutral: "\10fcbc";
$stmicon-select_all: "\10fcbd";
$stmicon-send: "\10fcbe";
$stmicon-save: "\10fcbf";
$stmicon-search: "\10fcc0";
$stmicon-screen_share: "\10fcc1";
$stmicon-sd_card: "\10fcc2";
$stmicon-sd_storage: "\10fcc3";
$stmicon-schedule: "\10fcc4";
$stmicon-screen_rotation: "\10fcc5";
$stmicon-screen_lock_landscape: "\10fcc6";
$stmicon-screen_lock_rotation: "\10fcc7";
$stmicon-scanner: "\10fcc8";
$stmicon-school: "\10fcc9";
$stmicon-router: "\10fcca";
$stmicon-rowing: "\10fccb";
$stmicon-rss_feed: "\10fccc";
$stmicon-rv_hookup: "\10fccd";
$stmicon-satellite: "\10fcce";
$stmicon-rounded_corner: "\10fccf";
$stmicon-rotate_left: "\10fcd0";
$stmicon-rotate_right: "\10fcd1";
$stmicon-restore_page: "\10fcd2";
$stmicon-ring_volume: "\10fcd3";
$stmicon-room_service: "\10fcd4";
$stmicon-room: "\10fcd5";
$stmicon-rotate_90_degrees_ccw: "\10fcd6";
$stmicon-restaurant: "\10fcd7";
$stmicon-restore: "\10fcd8";
$stmicon-replay_10: "\10fcd9";
$stmicon-reply: "\10fcda";
$stmicon-report_problem: "\10fcdb";
$stmicon-report: "\10fcdc";
$stmicon-replay_30: "\10fcdd";
$stmicon-reply_all: "\10fcde";
$stmicon-replay: "\10fcdf";
$stmicon-repeat_one: "\10fce0";
$stmicon-replay_5: "\10fce1";
$stmicon-repeat: "\10fce2";
$stmicon-remove_red_eye: "\10fce3";
$stmicon-remove_shopping_cart: "\10fce4";
$stmicon-remove: "\10fce5";
$stmicon-reorder: "\10fce6";
$stmicon-refresh: "\10fce7";
$stmicon-remove_circle: "\10fce8";
$stmicon-remove_from_queue: "\10fce9";
$stmicon-remove_circle_outline: "\10fcea";
$stmicon-redeem: "\10fceb";
$stmicon-redo: "\10fcec";
$stmicon-receipt: "\10fced";
$stmicon-recent_actors: "\10fcee";
$stmicon-record_voice_over: "\10fcef";
$stmicon-radio_button_unchecked: "\10fcf0";
$stmicon-radio_button_checked: "\10fcf1";
$stmicon-radio: "\10fcf2";
$stmicon-rate_review: "\10fcf3";
$stmicon-pregnant_woman: "\10fcf4";
$stmicon-queue: "\10fcf5";
$stmicon-question_answer: "\10fcf6";
$stmicon-queue_play_next: "\10fcf7";
$stmicon-publish: "\10fcf8";
$stmicon-query_builder: "\10fcf9";
$stmicon-power_settings_new: "\10fcfa";
$stmicon-present_to_all: "\10fcfb";
$stmicon-print: "\10fcfc";
$stmicon-priority_high: "\10fcfd";
$stmicon-power_input: "\10fcfe";
$stmicon-pool: "\10fcff";
$stmicon-portable_wifi_off: "\10fd00";
$stmicon-portrait: "\10fd01";
$stmicon-polymer: "\10fd02";
$stmicon-playlist_add: "\10fd03";
$stmicon-playlist_play: "\10fd04";
$stmicon-poll: "\10fd05";
$stmicon-play_for_work: "\10fd06";
$stmicon-playlist_add_check: "\10fd07";
$stmicon-plus_one: "\10fd08";
$stmicon-play_circle_filled: "\10fd09";
$stmicon-play_circle_outline: "\10fd0a";
$stmicon-play_arrow: "\10fd0b";
$stmicon-picture_in_picture: "\10fd0c";
$stmicon-pin_drop: "\10fd0d";
$stmicon-place: "\10fd0e";
$stmicon-pie_chart: "\10fd0f";
$stmicon-phonelink_lock: "\10fd10";
$stmicon-photo: "\10fd11";
$stmicon-picture_as_pdf: "\10fd12";
$stmicon-picture_in_picture_alt: "\10fd13";
$stmicon-photo_size_select_actual: "\10fd14";
$stmicon-photo_size_select_large: "\10fd15";
$stmicon-photo_size_select_small: "\10fd16";
$stmicon-photo_camera: "\10fd17";
$stmicon-photo_filter: "\10fd18";
$stmicon-photo_library: "\10fd19";
$stmicon-phonelink_setup: "\10fd1a";
$stmicon-phonelink: "\10fd1b";
$stmicon-photo_album: "\10fd1c";
$stmicon-phone: "\10fd1d";
$stmicon-phonelink_erase: "\10fd1e";
$stmicon-phonelink_off: "\10fd1f";
$stmicon-phonelink_ring: "\10fd20";
$stmicon-phone_paused: "\10fd21";
$stmicon-phone_locked: "\10fd22";
$stmicon-phone_missed: "\10fd23";
$stmicon-phone_iphone: "\10fd24";
$stmicon-pets: "\10fd25";
$stmicon-phone_android: "\10fd26";
$stmicon-phone_forwarded: "\10fd27";
$stmicon-phone_in_talk: "\10fd28";
$stmicon-personal_video: "\10fd29";
$stmicon-phone_bluetooth_speaker: "\10fd2a";
$stmicon-person_pin_circle: "\10fd2b";
$stmicon-person_pin: "\10fd2c";
$stmicon-person: "\10fd2d";
$stmicon-perm_scan_wifi: "\10fd2e";
$stmicon-person_add: "\10fd2f";
$stmicon-person_outline: "\10fd30";
$stmicon-perm_identity: "\10fd31";
$stmicon-perm_phone_msg: "\10fd32";
$stmicon-perm_data_setting: "\10fd33";
$stmicon-perm_device_information: "\10fd34";
$stmicon-perm_media: "\10fd35";
$stmicon-perm_contact_calendar: "\10fd36";
$stmicon-panorama_horizontal: "\10fd37";
$stmicon-people: "\10fd38";
$stmicon-pause_circle_outline: "\10fd39";
$stmicon-pause: "\10fd3a";
$stmicon-payment: "\10fd3b";
$stmicon-people_outline: "\10fd3c";
$stmicon-panorama: "\10fd3d";
$stmicon-party_mode: "\10fd3e";
$stmicon-pause_circle_filled: "\10fd3f";
$stmicon-panorama_vertical: "\10fd40";
$stmicon-panorama_wide_angle: "\10fd41";
$stmicon-panorama_fish_eye: "\10fd42";
$stmicon-open_in_new: "\10fd43";
$stmicon-pageview: "\10fd44";
$stmicon-palette: "\10fd45";
$stmicon-pan_tool: "\10fd46";
$stmicon-open_in_browser: "\10fd47";
$stmicon-pages: "\10fd48";
$stmicon-opacity: "\10fd49";
$stmicon-open_with: "\10fd4a";
$stmicon-notifications: "\10fd4b";
$stmicon-offline_pin: "\10fd4c";
$stmicon-ondemand_video: "\10fd4d";
$stmicon-note: "\10fd4e";
$stmicon-notifications_active: "\10fd4f";
$stmicon-notifications_paused: "\10fd50";
$stmicon-notifications_none: "\10fd51";
$stmicon-notifications_off: "\10fd52";
$stmicon-new_releases: "\10fd53";
$stmicon-not_interested: "\10fd54";
$stmicon-note_add: "\10fd55";
$stmicon-no_sim: "\10fd56";
$stmicon-next_week: "\10fd57";
$stmicon-nfc: "\10fd58";
$stmicon-no_encryption: "\10fd59";
$stmicon-network_check: "\10fd5a";
$stmicon-network_wifi-path1: "\10fd5b";
$stmicon-network_wifi-path2: "\10fd5c";
$stmicon-near_me: "\10fd5d";
$stmicon-network_cell-path1: "\10fd5e";
$stmicon-network_cell-path2: "\10fd5f";
$stmicon-network_locked: "\10fd60";
$stmicon-navigation: "\10fd61";
$stmicon-nature: "\10fd62";
$stmicon-navigate_before: "\10fd63";
$stmicon-navigate_next: "\10fd64";
$stmicon-move_to_inbox: "\10fd65";
$stmicon-nature_people: "\10fd66";
$stmicon-movie: "\10fd67";
$stmicon-multiline_chart: "\10fd68";
$stmicon-my_location: "\10fd69";
$stmicon-motorcycle: "\10fd6a";
$stmicon-movie_creation: "\10fd6b";
$stmicon-movie_filter: "\10fd6c";
$stmicon-more: "\10fd6d";
$stmicon-mouse: "\10fd6e";
$stmicon-mood_bad: "\10fd6f";
$stmicon-more_horiz: "\10fd70";
$stmicon-more_vert: "\10fd71";
$stmicon-monetization_on: "\10fd72";
$stmicon-monochrome_photos: "\10fd73";
$stmicon-mood: "\10fd74";
$stmicon-money_off: "\10fd75";
$stmicon-mms: "\10fd76";
$stmicon-mode_comment: "\10fd77";
$stmicon-merge_type: "\10fd78";
$stmicon-message: "\10fd79";
$stmicon-memory: "\10fd7a";
$stmicon-menu: "\10fd7b";
$stmicon-map: "\10fd7c";
$stmicon-markunread_mailbox: "\10fd7d";
$stmicon-markunread: "\10fd7e";
$stmicon-low_priority: "\10fd7f";
$stmicon-mail_outline: "\10fd80";
$stmicon-mail: "\10fd81";
$stmicon-looks_two: "\10fd82";
$stmicon-loyalty: "\10fd83";
$stmicon-looks: "\10fd84";
$stmicon-loop: "\10fd85";
$stmicon-loupe: "\10fd86";
$stmicon-looks_6: "\10fd87";
$stmicon-looks_one: "\10fd88";
$stmicon-looks_3: "\10fd89";
$stmicon-looks_5: "\10fd8a";
$stmicon-looks_4: "\10fd8b";
$stmicon-location_on: "\10fd8c";
$stmicon-lock: "\10fd8d";
$stmicon-local_play: "\10fd8e";
$stmicon-location_searching: "\10fd8f";
$stmicon-lock_open: "\10fd90";
$stmicon-local_see: "\10fd91";
$stmicon-location_city: "\10fd92";
$stmicon-location_disabled: "\10fd93";
$stmicon-local_taxi: "\10fd94";
$stmicon-location_off: "\10fd95";
$stmicon-local_printshop: "\10fd96";
$stmicon-local_shipping: "\10fd97";
$stmicon-local_pharmacy: "\10fd98";
$stmicon-local_post_office: "\10fd99";
$stmicon-local_parking: "\10fd9a";
$stmicon-local_phone: "\10fd9b";
$stmicon-local_pizza: "\10fd9c";
$stmicon-local_offer: "\10fd9d";
$stmicon-local_mall: "\10fd9e";
$stmicon-local_movies: "\10fd9f";
$stmicon-local_hospital: "\10fda0";
$stmicon-local_laundry_service: "\10fda1";
$stmicon-local_library: "\10fda2";
$stmicon-local_gas_station: "\10fda3";
$stmicon-local_grocery_store: "\10fda4";
$stmicon-local_dining: "\10fda5";
$stmicon-local_drink: "\10fda6";
$stmicon-local_florist: "\10fda7";
$stmicon-local_car_wash: "\10fda8";
$stmicon-local_convenience_store: "\10fda9";
$stmicon-local_airport: "\10fdaa";
$stmicon-local_atm: "\10fdab";
$stmicon-local_bar: "\10fdac";
$stmicon-local_cafe: "\10fdad";
$stmicon-local_activity: "\10fdae";
$stmicon-live_help: "\10fdaf";
$stmicon-live_tv: "\10fdb0";
$stmicon-link: "\10fdb1";
$stmicon-linked_camera: "\10fdb2";
$stmicon-list: "\10fdb3";
$stmicon-linear_scale: "\10fdb4";
$stmicon-line_style: "\10fdb5";
$stmicon-line_weight: "\10fdb6";
$stmicon-layers: "\10fdb7";
$stmicon-library_books: "\10fdb8";
$stmicon-lens: "\10fdb9";
$stmicon-library_add: "\10fdba";
$stmicon-leak_add: "\10fdbb";
$stmicon-leak_remove: "\10fdbc";
$stmicon-layers_clear: "\10fdbd";
$stmicon-laptop: "\10fdbe";
$stmicon-last_page: "\10fdbf";
$stmicon-launch: "\10fdc0";
$stmicon-language: "\10fdc1";
$stmicon-laptop_chromebook: "\10fdc2";
$stmicon-laptop_mac: "\10fdc3";
$stmicon-laptop_windows: "\10fdc4";
$stmicon-landscape: "\10fdc5";
$stmicon-kitchen: "\10fdc6";
$stmicon-label: "\10fdc7";
$stmicon-keyboard_hide: "\10fdc8";
$stmicon-keyboard_return: "\10fdc9";
$stmicon-keyboard_tab: "\10fdca";
$stmicon-keyboard_voice: "\10fdcb";
$stmicon-keyboard: "\10fdcc";
$stmicon-keyboard_arrow_up: "\10fdcd";
$stmicon-keyboard_backspace: "\10fdce";
$stmicon-keyboard_capslock: "\10fdcf";
$stmicon-keyboard_arrow_left: "\10fdd0";
$stmicon-keyboard_arrow_right: "\10fdd1";
$stmicon-iso: "\10fdd2";
$stmicon-keyboard_arrow_down: "\10fdd3";
$stmicon-insert_invitation: "\10fdd4";
$stmicon-invert_colors_off: "\10fdd5";
$stmicon-invert_colors: "\10fdd6";
$stmicon-insert_emoticon: "\10fdd7";
$stmicon-insert_link: "\10fdd8";
$stmicon-insert_photo: "\10fdd9";
$stmicon-insert_comment: "\10fdda";
$stmicon-insert_drive_file: "\10fddb";
$stmicon-input: "\10fddc";
$stmicon-insert_chart: "\10fddd";
$stmicon-info: "\10fdde";
$stmicon-import_export: "\10fddf";
$stmicon-indeterminate_check_box: "\10fde0";
$stmicon-import_contacts: "\10fde1";
$stmicon-important_devices: "\10fde2";
$stmicon-inbox: "\10fde3";
$stmicon-hourglass_empty: "\10fde4";
$stmicon-image: "\10fde5";
$stmicon-https: "\10fde6";
$stmicon-image_aspect_ratio: "\10fde7";
$stmicon-highlight: "\10fde8";
$stmicon-hourglass_full: "\10fde9";
$stmicon-http: "\10fdea";
$stmicon-history: "\10fdeb";
$stmicon-highlight_off: "\10fdec";
$stmicon-home: "\10fded";
$stmicon-help: "\10fdee";
$stmicon-high_quality: "\10fdef";
$stmicon-hearing: "\10fdf0";
$stmicon-help_outline: "\10fdf1";
$stmicon-headset: "\10fdf2";
$stmicon-healing: "\10fdf3";
$stmicon-hdr_weak: "\10fdf4";
$stmicon-hdr_off: "\10fdf5";
$stmicon-hdr_on: "\10fdf6";
$stmicon-hdr_strong: "\10fdf7";
$stmicon-group: "\10fdf8";
$stmicon-hd: "\10fdf9";
$stmicon-group_add: "\10fdfa";
$stmicon-group_work: "\10fdfb";
$stmicon-grid_off: "\10fdfc";
$stmicon-grid_on: "\10fdfd";
$stmicon-grain: "\10fdfe";
$stmicon-gps_fixed: "\10fdff";
$stmicon-gps_not_fixed: "\10fe00";
$stmicon-grade: "\10fe01";
$stmicon-gradient: "\10fe02";
$stmicon-gif: "\10fe03";
$stmicon-golf_course: "\10fe04";
$stmicon-gps_off: "\10fe05";
$stmicon-forum: "\10fe06";
$stmicon-gesture: "\10fe07";
$stmicon-get_app: "\10fe08";
$stmicon-gavel: "\10fe09";
$stmicon-forward_30: "\10fe0a";
$stmicon-gamepad: "\10fe0b";
$stmicon-games: "\10fe0c";
$stmicon-functions: "\10fe0d";
$stmicon-g_translate: "\10fe0e";
$stmicon-fullscreen: "\10fe0f";
$stmicon-forward: "\10fe10";
$stmicon-free_breakfast: "\10fe11";
$stmicon-fullscreen_exit: "\10fe12";
$stmicon-forward_5: "\10fe13";
$stmicon-forward_10: "\10fe14";
$stmicon-format_textdirection_r_to_l: "\10fe15";
$stmicon-format_underlined: "\10fe16";
$stmicon-format_strikethrough: "\10fe17";
$stmicon-format_textdirection_l_to_r: "\10fe18";
$stmicon-format_shapes: "\10fe19";
$stmicon-format_size: "\10fe1a";
$stmicon-format_list_bulleted: "\10fe1b";
$stmicon-format_paint: "\10fe1c";
$stmicon-format_quote: "\10fe1d";
$stmicon-format_line_spacing: "\10fe1e";
$stmicon-format_list_numbered: "\10fe1f";
$stmicon-format_indent_decrease: "\10fe20";
$stmicon-format_indent_increase: "\10fe21";
$stmicon-format_color_reset: "\10fe22";
$stmicon-format_color_fill-path1: "\10fe23";
$stmicon-format_color_fill-path2: "\10fe24";
$stmicon-format_color_text-path1: "\10fe25";
$stmicon-format_color_text-path2: "\10fe26";
$stmicon-format_align_right: "\10fe27";
$stmicon-format_bold: "\10fe28";
$stmicon-format_clear: "\10fe29";
$stmicon-format_align_justify: "\10fe2a";
$stmicon-format_align_left: "\10fe2b";
$stmicon-font_download: "\10fe2c";
$stmicon-format_align_center: "\10fe2d";
$stmicon-folder_shared: "\10fe2e";
$stmicon-folder_special: "\10fe2f";
$stmicon-folder: "\10fe30";
$stmicon-flight: "\10fe31";
$stmicon-flip_to_front: "\10fe32";
$stmicon-folder_open: "\10fe33";
$stmicon-flip: "\10fe34";
$stmicon-flash_on: "\10fe35";
$stmicon-flight_land: "\10fe36";
$stmicon-flight_takeoff: "\10fe37";
$stmicon-flip_to_back: "\10fe38";
$stmicon-flare: "\10fe39";
$stmicon-flash_auto: "\10fe3a";
$stmicon-flash_off: "\10fe3b";
$stmicon-first_page: "\10fe3c";
$stmicon-flag: "\10fe3d";
$stmicon-find_replace: "\10fe3e";
$stmicon-fingerprint: "\10fe3f";
$stmicon-fitness_center: "\10fe40";
$stmicon-filter_tilt_shift: "\10fe41";
$stmicon-filter: "\10fe42";
$stmicon-find_in_page: "\10fe43";
$stmicon-filter_none: "\10fe44";
$stmicon-filter_vintage: "\10fe45";
$stmicon-filter_frames: "\10fe46";
$stmicon-filter_list: "\10fe47";
$stmicon-filter_hdr: "\10fe48";
$stmicon-filter_center_focus: "\10fe49";
$stmicon-filter_drama: "\10fe4a";
$stmicon-filter_9: "\10fe4b";
$stmicon-filter_b_and_w: "\10fe4c";
$stmicon-filter_4: "\10fe4d";
$stmicon-filter_7: "\10fe4e";
$stmicon-filter_8: "\10fe4f";
$stmicon-filter_9_plus: "\10fe50";
$stmicon-filter_5: "\10fe51";
$stmicon-filter_6: "\10fe52";
$stmicon-filter_2: "\10fe53";
$stmicon-filter_3: "\10fe54";
$stmicon-filter_1: "\10fe55";
$stmicon-fiber_smart_record: "\10fe56";
$stmicon-fiber_new: "\10fe57";
$stmicon-fiber_pin: "\10fe58";
$stmicon-feedback: "\10fe59";
$stmicon-fiber_dvr: "\10fe5a";
$stmicon-fiber_manual_record: "\10fe5b";
$stmicon-featured_play_list: "\10fe5c";
$stmicon-featured_video: "\10fe5d";
$stmicon-fast_forward: "\10fe5e";
$stmicon-favorite_border: "\10fe5f";
$stmicon-favorite: "\10fe60";
$stmicon-fast_rewind: "\10fe61";
$stmicon-exposure: "\10fe62";
$stmicon-extension: "\10fe63";
$stmicon-face: "\10fe64";
$stmicon-exposure_plus_1: "\10fe65";
$stmicon-exposure_plus_2: "\10fe66";
$stmicon-exposure_zero: "\10fe67";
$stmicon-explicit: "\10fe68";
$stmicon-explore: "\10fe69";
$stmicon-exposure_neg_1: "\10fe6a";
$stmicon-exposure_neg_2: "\10fe6b";
$stmicon-exit_to_app: "\10fe6c";
$stmicon-expand_less: "\10fe6d";
$stmicon-expand_more: "\10fe6e";
$stmicon-event_seat: "\10fe6f";
$stmicon-event_note: "\10fe70";
$stmicon-event: "\10fe71";
$stmicon-event_busy: "\10fe72";
$stmicon-event_available: "\10fe73";
$stmicon-error: "\10fe74";
$stmicon-euro_symbol: "\10fe75";
$stmicon-ev_station: "\10fe76";
$stmicon-error_outline: "\10fe77";
$stmicon-enhanced_encryption: "\10fe78";
$stmicon-equalizer: "\10fe79";
$stmicon-eject: "\10fe7a";
$stmicon-email: "\10fe7b";
$stmicon-drive_eta: "\10fe7c";
$stmicon-edit_location: "\10fe7d";
$stmicon-edit: "\10fe7e";
$stmicon-dvr: "\10fe7f";
$stmicon-done_all: "\10fe80";
$stmicon-donut_small: "\10fe81";
$stmicon-drafts: "\10fe82";
$stmicon-drag_handle: "\10fe83";
$stmicon-done: "\10fe84";
$stmicon-donut_large: "\10fe85";
$stmicon-dock: "\10fe86";
$stmicon-domain: "\10fe87";
$stmicon-dns: "\10fe88";
$stmicon-directions_walk: "\10fe89";
$stmicon-directions: "\10fe8a";
$stmicon-disc_full: "\10fe8b";
$stmicon-directions_transit: "\10fe8c";
$stmicon-directions_boat: "\10fe8d";
$stmicon-directions_bus: "\10fe8e";
$stmicon-directions_railway: "\10fe8f";
$stmicon-directions_run: "\10fe90";
$stmicon-directions_subway: "\10fe91";
$stmicon-dialer_sip: "\10fe92";
$stmicon-dialpad: "\10fe93";
$stmicon-directions_bike: "\10fe94";
$stmicon-directions_car: "\10fe95";
$stmicon-devices: "\10fe96";
$stmicon-device_hub: "\10fe97";
$stmicon-devices_other: "\10fe98";
$stmicon-developer_board: "\10fe99";
$stmicon-developer_mode: "\10fe9a";
$stmicon-details: "\10fe9b";
$stmicon-desktop_mac: "\10fe9c";
$stmicon-desktop_windows: "\10fe9d";
$stmicon-delete_sweep: "\10fe9e";
$stmicon-delete: "\10fe9f";
$stmicon-description: "\10fea0";
$stmicon-dehaze: "\10fea1";
$stmicon-delete_forever: "\10fea2";
$stmicon-data_usage: "\10fea3";
$stmicon-date_range: "\10fea4";
$stmicon-dashboard: "\10fea5";
$stmicon-crop_rotate: "\10fea6";
$stmicon-crop_square: "\10fea7";
$stmicon-crop: "\10fea8";
$stmicon-crop_original: "\10fea9";
$stmicon-crop_portrait: "\10feaa";
$stmicon-crop_7_5: "\10feab";
$stmicon-crop_16_9: "\10feac";
$stmicon-crop_din: "\10fead";
$stmicon-crop_free: "\10feae";
$stmicon-crop_landscape: "\10feaf";
$stmicon-crop_5_4: "\10feb0";
$stmicon-crop_3_2: "\10feb1";
$stmicon-credit_card: "\10feb2";
$stmicon-create: "\10feb3";
$stmicon-copyright: "\10feb4";
$stmicon-create_new_folder: "\10feb5";
$stmicon-control_point_duplicate: "\10feb6";
$stmicon-control_point: "\10feb7";
$stmicon-contact_mail: "\10feb8";
$stmicon-contacts: "\10feb9";
$stmicon-contact_phone: "\10feba";
$stmicon-compare: "\10febb";
$stmicon-computer: "\10febc";
$stmicon-confirmation_number: "\10febd";
$stmicon-comment: "\10febe";
$stmicon-compare_arrows: "\10febf";
$stmicon-colorize: "\10fec0";
$stmicon-code: "\10fec1";
$stmicon-collections_bookmark: "\10fec2";
$stmicon-collections: "\10fec3";
$stmicon-color_lens: "\10fec4";
$stmicon-cloud: "\10fec5";
$stmicon-cloud_upload: "\10fec6";
$stmicon-cloud_download: "\10fec7";
$stmicon-cloud_off: "\10fec8";
$stmicon-cloud_queue: "\10fec9";
$stmicon-cloud_circle: "\10feca";
$stmicon-cloud_done: "\10fecb";
$stmicon-close: "\10fecc";
$stmicon-closed_caption: "\10fecd";
$stmicon-clear_all: "\10fece";
$stmicon-clear: "\10fecf";
$stmicon-class: "\10fed0";
$stmicon-child_friendly: "\10fed1";
$stmicon-chrome_reader_mode: "\10fed2";
$stmicon-chevron_left: "\10fed3";
$stmicon-chevron_right: "\10fed4";
$stmicon-child_care: "\10fed5";
$stmicon-check_circle: "\10fed6";
$stmicon-check: "\10fed7";
$stmicon-check_box_outline_blank: "\10fed8";
$stmicon-check_box: "\10fed9";
$stmicon-chat_bubble: "\10feda";
$stmicon-chat: "\10fedb";
$stmicon-center_focus_weak: "\10fedc";
$stmicon-change_history: "\10fedd";
$stmicon-chat_bubble_outline: "\10fede";
$stmicon-cast: "\10fedf";
$stmicon-center_focus_strong: "\10fee0";
$stmicon-cast_connected: "\10fee1";
$stmicon-card_membership: "\10fee2";
$stmicon-card_travel: "\10fee3";
$stmicon-casino: "\10fee4";
$stmicon-cancel: "\10fee5";
$stmicon-card_giftcard: "\10fee6";
$stmicon-camera_rear: "\10fee7";
$stmicon-camera: "\10fee8";
$stmicon-camera_enhance: "\10fee9";
$stmicon-camera_front: "\10feea";
$stmicon-camera_roll: "\10feeb";
$stmicon-call_to_action: "\10feec";
$stmicon-call: "\10feed";
$stmicon-camera_alt: "\10feee";
$stmicon-call_received: "\10feef";
$stmicon-call_split: "\10fef0";
$stmicon-call_missed_outgoing: "\10fef1";
$stmicon-call_missed: "\10fef2";
$stmicon-call_made: "\10fef3";
$stmicon-call_merge: "\10fef4";
$stmicon-cake: "\10fef5";
$stmicon-call_end: "\10fef6";
$stmicon-business: "\10fef7";
$stmicon-cached: "\10fef8";
$stmicon-burst_mode: "\10fef9";
$stmicon-business_center: "\10fefa";
$stmicon-bubble_chart: "\10fefb";
$stmicon-bug_report: "\10fefc";
$stmicon-build: "\10fefd";
$stmicon-brush: "\10fefe";
$stmicon-brightness_low: "\10feff";
$stmicon-brightness_medium: "\10ff00";
$stmicon-brightness_auto: "\10ff01";
$stmicon-brightness_high: "\10ff02";
$stmicon-broken_image: "\10ff03";
$stmicon-blur_off: "\10ff04";
$stmicon-brightness_4: "\10ff05";
$stmicon-brightness_5: "\10ff06";
$stmicon-brightness_6: "\10ff07";
$stmicon-brightness_7: "\10ff08";
$stmicon-brightness_2: "\10ff09";
$stmicon-border_top: "\10ff0a";
$stmicon-border_vertical: "\10ff0b";
$stmicon-branding_watermark: "\10ff0c";
$stmicon-brightness_1: "\10ff0d";
$stmicon-border_style: "\10ff0e";
$stmicon-border_inner: "\10ff0f";
$stmicon-border_outer: "\10ff10";
$stmicon-border_right: "\10ff11";
$stmicon-border_left: "\10ff12";
$stmicon-border_color-path1: "\10ff13";
$stmicon-border_color-path2: "\10ff14";
$stmicon-border_color-path3: "\10ff15";
$stmicon-border_horizontal: "\10ff16";
$stmicon-border_bottom: "\10ff17";
$stmicon-border_clear: "\10ff18";
$stmicon-bookmark: "\10ff19";
$stmicon-border_all: "\10ff1a";
$stmicon-bookmark_border: "\10ff1b";
$stmicon-blur_on: "\10ff1c";
$stmicon-book: "\10ff1d";
$stmicon-blur_circular: "\10ff1e";
$stmicon-blur_linear: "\10ff1f";
$stmicon-bluetooth: "\10ff20";
$stmicon-bluetooth_audio: "\10ff21";
$stmicon-bluetooth_connected: "\10ff22";
$stmicon-bluetooth_disabled: "\10ff23";
$stmicon-bluetooth_searching: "\10ff24";
$stmicon-beenhere: "\10ff25";
$stmicon-block: "\10ff26";
$stmicon-battery_std: "\10ff27";
$stmicon-battery_unknown: "\10ff28";
$stmicon-beach_access: "\10ff29";
$stmicon-battery_charging_90-path1: "\10ff2a";
$stmicon-battery_charging_90-path2: "\10ff2b";
$stmicon-battery_charging_full: "\10ff2c";
$stmicon-battery_full: "\10ff2d";
$stmicon-battery_charging_60-path1: "\10ff2e";
$stmicon-battery_charging_60-path2: "\10ff2f";
$stmicon-battery_charging_80-path1: "\10ff30";
$stmicon-battery_charging_80-path2: "\10ff31";
$stmicon-battery_charging_30-path1: "\10ff32";
$stmicon-battery_charging_30-path2: "\10ff33";
$stmicon-battery_charging_50-path1: "\10ff34";
$stmicon-battery_charging_50-path2: "\10ff35";
$stmicon-battery_alert: "\10ff36";
$stmicon-battery_charging_20-path1: "\10ff37";
$stmicon-battery_charging_20-path2: "\10ff38";
$stmicon-backup: "\10ff39";
$stmicon-battery_50-path1: "\10ff3a";
$stmicon-battery_50-path2: "\10ff3b";
$stmicon-battery_60-path1: "\10ff3c";
$stmicon-battery_60-path2: "\10ff3d";
$stmicon-battery_80-path1: "\10ff3e";
$stmicon-battery_80-path2: "\10ff3f";
$stmicon-battery_90-path1: "\10ff40";
$stmicon-battery_90-path2: "\10ff41";
$stmicon-battery_30-path1: "\10ff42";
$stmicon-battery_30-path2: "\10ff43";
$stmicon-av_timer: "\10ff44";
$stmicon-backspace: "\10ff45";
$stmicon-battery_20-path1: "\10ff46";
$stmicon-battery_20-path2: "\10ff47";
$stmicon-autorenew: "\10ff48";
$stmicon-attachment: "\10ff49";
$stmicon-audiotrack: "\10ff4a";
$stmicon-assistant: "\10ff4b";
$stmicon-attach_money: "\10ff4c";
$stmicon-assignment: "\10ff4d";
$stmicon-assistant_photo: "\10ff4e";
$stmicon-attach_file: "\10ff4f";
$stmicon-assignment_turned_in: "\10ff50";
$stmicon-assessment: "\10ff51";
$stmicon-assignment_late: "\10ff52";
$stmicon-assignment_return: "\10ff53";
$stmicon-assignment_returned: "\10ff54";
$stmicon-assignment_ind: "\10ff55";
$stmicon-art_track: "\10ff56";
$stmicon-aspect_ratio: "\10ff57";
$stmicon-arrow_forward: "\10ff58";
$stmicon-arrow_drop_down_circle: "\10ff59";
$stmicon-arrow_drop_down: "\10ff5a";
$stmicon-arrow_drop_up: "\10ff5b";
$stmicon-arrow_upward: "\10ff5c";
$stmicon-arrow_back: "\10ff5d";
$stmicon-arrow_downward: "\10ff5e";
$stmicon-announcement: "\10ff5f";
$stmicon-apps: "\10ff60";
$stmicon-archive: "\10ff61";
$stmicon-all_out: "\10ff62";
$stmicon-android: "\10ff63";
$stmicon-album: "\10ff64";
$stmicon-all_inclusive: "\10ff65";
$stmicon-alarm_off: "\10ff66";
$stmicon-alarm_on: "\10ff67";
$stmicon-alarm: "\10ff68";
$stmicon-airport_shuttle: "\10ff69";
$stmicon-alarm_add: "\10ff6a";
$stmicon-airplanemode_inactive: "\10ff6b";
$stmicon-airplay: "\10ff6c";
$stmicon-airplanemode_active: "\10ff6d";
$stmicon-airline_seat_legroom_reduced: "\10ff6e";
$stmicon-airline_seat_recline_extra: "\10ff6f";
$stmicon-airline_seat_recline_normal: "\10ff70";
$stmicon-airline_seat_legroom_extra: "\10ff71";
$stmicon-airline_seat_legroom_normal: "\10ff72";
$stmicon-airline_seat_flat: "\10ff73";
$stmicon-airline_seat_individual_suite: "\10ff74";
$stmicon-airline_seat_flat_angled: "\10ff75";
$stmicon-add_to_photos: "\10ff76";
$stmicon-add_to_queue: "\10ff77";
$stmicon-add: "\10ff78";
$stmicon-adjust: "\10ff79";
$stmicon-add_location: "\10ff7a";
$stmicon-add_shopping_cart: "\10ff7b";
$stmicon-add_circle: "\10ff7c";
$stmicon-add_circle_outline: "\10ff7d";
$stmicon-adb: "\10ff7e";
$stmicon-add_a_photo: "\10ff7f";
$stmicon-add_alarm: "\10ff80";
$stmicon-add_alert: "\10ff81";
$stmicon-add_box: "\10ff82";
$stmicon-account_circle: "\10ff83";
$stmicon-account_box: "\10ff84";
$stmicon-accessible: "\10ff85";
$stmicon-account_balance_wallet: "\10ff86";
$stmicon-account_balance: "\10ff87";
$stmicon-accessibility: "\10ff88";
$stmicon-access_alarm: "\10ff89";
$stmicon-access_time: "\10ff8a";
$stmicon-ac_unit: "\10ff8b";
$stmicon-access_alarms: "\10ff8c";
$stmicon-content_copy: "\10ff8d";
$stmicon-content_cut: "\10ff8e";
$stmicon-content_paste: "\10ff8f";
$stmicon-hotel-breakfast2: "\10ff90";
$stmicon-hotel-parking: "\10ff91";
$stmicon-hotel-internet: "\10ff92";
$stmicon-hotel-success: "\10ff93";
$stmicon-hotel-warning: "\10ff94";
$stmicon-hotel_agenda: "\10ff95";
$stmicon-hotel_air-conditioner: "\10ff96";
$stmicon-hotel_bathrobe: "\10ff97";
$stmicon-hotel_bathtub: "\10ff98";
$stmicon-hotel_bed-1: "\10ff99";
$stmicon-hotel_bed-2: "\10ff9a";
$stmicon-hotel_bed-3: "\10ff9b";
$stmicon-hotel_bed: "\10ff9c";
$stmicon-hotel_beds: "\10ff9d";
$stmicon-hotel_bell: "\10ff9e";
$stmicon-hotel_building-1: "\10ff9f";
$stmicon-hotel_building-2: "\10ffa0";
$stmicon-hotel_building: "\10ffa1";
$stmicon-hotel_bunk: "\10ffa2";
$stmicon-hotel_burj-al-arab: "\10ffa3";
$stmicon-hotel_calendar: "\10ffa4";
$stmicon-hotel_car: "\10ffa5";
$stmicon-hotel_cctv: "\10ffa6";
$stmicon-hotel_cocktail: "\10ffa7";
$stmicon-hotel_coffee: "\10ffa8";
$stmicon-hotel_cutlery: "\10ffa9";
$stmicon-hotel_doorknob-1: "\10ffaa";
$stmicon-hotel_doorknob-2: "\10ffab";
$stmicon-hotel_doorknob-3: "\10ffac";
$stmicon-hotel_doorknob: "\10ffad";
$stmicon-hotel_elevator-1: "\10ffae";
$stmicon-hotel_elevator: "\10ffaf";
$stmicon-hotel_escalator-1: "\10ffb0";
$stmicon-hotel_escalator: "\10ffb1";
$stmicon-hotel_exchange: "\10ffb2";
$stmicon-hotel_fast-food: "\10ffb3";
$stmicon-hotel_fire-extinguisher: "\10ffb4";
$stmicon-hotel_hairdryer-1: "\10ffb5";
$stmicon-hotel_hairdryer: "\10ffb6";
$stmicon-hotel_hanger: "\10ffb7";
$stmicon-hotel_hotel-1: "\10ffb8";
$stmicon-hotel_hotel-2: "\10ffb9";
$stmicon-hotel_hotel-3: "\10ffba";
$stmicon-hotel_hotel-4: "\10ffbb";
$stmicon-hotel_hotel-5: "\10ffbc";
$stmicon-hotel_hotel-sign-1: "\10ffbd";
$stmicon-hotel_hotel-sign-2: "\10ffbe";
$stmicon-hotel_hotel-sign-3: "\10ffbf";
$stmicon-hotel_hotel-sign-4: "\10ffc0";
$stmicon-hotel_hotel-sign: "\10ffc1";
$stmicon-hotel_hotel: "\10ffc2";
$stmicon-hotel_information: "\10ffc3";
$stmicon-hotel_laptop: "\10ffc4";
$stmicon-hotel_location: "\10ffc5";
$stmicon-hotel_luggage-1: "\10ffc6";
$stmicon-hotel_luggage-2: "\10ffc7";
$stmicon-hotel_luggage-3: "\10ffc8";
$stmicon-hotel_luggage-4: "\10ffc9";
$stmicon-hotel_luggage-5: "\10ffca";
$stmicon-hotel_luggage: "\10ffcb";
$stmicon-hotel_meal: "\10ffcc";
$stmicon-hotel_menu-1: "\10ffcd";
$stmicon-hotel_menu-2: "\10ffce";
$stmicon-hotel_menu-3: "\10ffcf";
$stmicon-hotel_menu-4: "\10ffd0";
$stmicon-hotel_menu-5: "\10ffd1";
$stmicon-hotel_menu: "\10ffd2";
$stmicon-hotel_monitor: "\10ffd3";
$stmicon-hotel_no-pictures: "\10ffd4";
$stmicon-hotel_no-smoking: "\10ffd5";
$stmicon-hotel_parking-1: "\10ffd6";
$stmicon-hotel_parking: "\10ffd7";
$stmicon-hotel_passport: "\10ffd8";
$stmicon-hotel_reception: "\10ffd9";
$stmicon-hotel_restaurant: "\10ffda";
$stmicon-hotel_room-key-1: "\10ffdb";
$stmicon-hotel_room-key-2: "\10ffdc";
$stmicon-hotel_room-key: "\10ffdd";
$stmicon-hotel_room-service-1: "\10ffde";
$stmicon-hotel_room-service-2: "\10ffdf";
$stmicon-hotel_room-service-3: "\10ffe0";
$stmicon-hotel_room-service: "\10ffe1";
$stmicon-hotel_safebox: "\10ffe2";
$stmicon-hotel_shower: "\10ffe3";
$stmicon-hotel_slippers: "\10ffe4";
$stmicon-hotel_smoking: "\10ffe5";
$stmicon-hotel_stool: "\10ffe6";
$stmicon-hotel_suitcase-1: "\10ffe7";
$stmicon-hotel_suitcase: "\10ffe8";
$stmicon-hotel_telephone-1: "\10ffe9";
$stmicon-hotel_telephone: "\10ffea";
$stmicon-hotel_television: "\10ffeb";
$stmicon-hotel_towel: "\10ffec";
$stmicon-hotel_vacuum-cleaner: "\10ffed";
$stmicon-hotel_wifi: "\10ffee";
$stmicon-hotel-peoples: "\10ffef";
$stmicon-hotel-breakfast: "\10fff0";
$stmicon-hotel-concierge: "\10fff1";
$stmicon-hotel-drinks: "\10fff2";
$stmicon-hotel-event: "\10fff3";
$stmicon-hotel-fitness: "\10fff4";
$stmicon-hotel-laundry: "\10fff5";
$stmicon-hotel-map-pin: "\10fff6";
$stmicon-hotel-meetings: "\10fff7";
$stmicon-hotel-pool: "\10fff8";
$stmicon-hotel-quotes: "\10fff9";
$stmicon-hotel-restaurant: "\10fffa";
$stmicon-hotel-sauna: "\10fffb";
$stmicon-hotel-send: "\10fffc";
$stmicon-hotel-spa: "\10fffd";
$stmicon-hotel-training: "\10fffe";
$stmicon-hotel-wifi: "\10ffff";

