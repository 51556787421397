//layout 1
@import "../../variables";
@import "../../mixins";
@import "../../icons";
@import "_base";

.stm_carousel_style_1 {
    position: relative;
    &.full-visible {
        .owl-stage-outer {
            overflow: visible;
        }
    }
    .stm_carousel {
        &__description {
            position: relative;
            text-align: center;
            -webkit-backface-visibility: hidden;
            transform: translateZ(0);
            line-height: 27px;
            font-size: 18px;
        }
        &__title {
            padding: 42px 20px 13px;
            color: #111111 !important;
        }
        &__big {
            position: relative;
        }
        &__small {
            margin: 0 -5px;
        }
        &__pagination {
            position: absolute;
            bottom: 35px;
            right: 140px;
            z-index: 25;
        }

        &__single {
            cursor: pointer;
            &_small {
                padding: 0 5px;
                &.stm_owl__glitches {
                    max-width: 16.66%;
                }
                &.current {
                    &:before {
                        opacity: 1;
                    }
                }
                &:before {
                    @include pseudo();
                    @include absoluted();
                    left: 5px;
                    right: 5px;
                    background: rgba(0, 0, 0, 0.5);
                    opacity: 0;
                    z-index: 25;
                }
            }

            &:before, &:after {
                content: '';
                width: 120px;
                display: block;
                height: 1px;
                background-color: #fff;
                position: absolute;
                @include absAlign(c);
                z-index: 10;
                transition: all .3s ease;
                opacity: 0;
            }
            &:before {
                transform: rotate(90deg) translateY(-50%) translateX(-50%);
                margin-left: -60px;
                margin-top: 60px;
            }
            a {
                display: block;
                position: relative;
                &:before {
                    content: '';
                    @include absoluted();
                    background-color: rgba(#000, .75);
                    transition: all .3s ease;
                    opacity: 0;
                }
            }
            &:hover {
                &:before, &:after {
                    opacity: 1;
                }
                a {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        .owl-loaded {
            .stm_carousel__single_small.stm_owl__glitches {
                max-width: 100%;
            }
        }
        @include breakpoint(mobile) {
            &__pagination,
            .owl-controls {
                display: none;
            }
            &__title {
                min-height: 100%;
                padding: 15px;
            }
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .owl-dot {
            padding: 0;
            border-radius: 50%;
            span {
                display: block;
                width: 12px;
                height: 12px;
                background: #fff;
                border-radius: 50%;
            }
            &.active {
                span {
                    border: 0;
                    opacity: 1;
                }
            }
        }
    }
    .owl-stage {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.stm_carousel__navigation_style_1 {
        .owl-nav {
            button.owl-next, button.owl-prev {
                @include size(80);
                background-color: rgba(#000, .4);
                border-radius: 4px;
                @include breakpoint(mobile) {
                    width: 40px !important;
                }
            }
        }
    }
    .stm_carousel__pagination {
        display: block !important;
        position: static;
        margin: 37px 0 36px;
        text-align: center;
        span {
            line-height: 14px;
            vertical-align: middle;
            color: rgba(255,255,255,.5) !important;
            &.sep {
                position: relative;
                text-transform: uppercase;
            }
        }
    }
    .owl-nav {
        opacity: 0;
        transition: .3s ease;
    }
    .owl-carousel:hover {
        .owl-nav {
            opacity: 1;
        }
    }
}

.stm_layout_chicago .stm_carousel_style_1:after {
    display: none !important;
}

.no-lightgallery {
    &.stm_carousel_style_1 .stm_carousel__single:before,
    &.stm_carousel_style_1 .stm_carousel__single:after {
        display: none !important;
    }
}

@include breakpoint(mobile) {
    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next {
        width: 50px;
        height: 50px;
        font-size: 14px;
    }
    .owl-carousel .owl-nav button.owl-prev {
        left: 15px;
    }
    .owl-carousel .owl-nav button.owl-next {
        right: 15px;
    }
}