//Hotel
@import "../../variables";
@import "../../mixins";
//@import "../../icons/hotel/variables";
@import "base";

.stm_testimonials_style_1 {
    margin-bottom: 30px;
    .owl-stage-outer {
        padding: 0 15px;
        margin: 0 -15px;
    }
    .owl-dots {
        margin-top: 20px;
        .owl-dot {
            display: inline-block;
            margin-right: 10px;
            width: 10px;
            height: 10px;
            background-color: #ccc;
        }
    }
    .owl-controls {
        display: block;
        .owl-nav {
            width: 420px;
            position: absolute;
            bottom: 140px;
            left: 50%;
            margin-left: -210px;
            .owl-next,
            .owl-prev {
                width: 59px;
                height: 59px;
                line-height: 59px;
                border-radius: 50%;
                transition: all 0.3s;
                &:before {
                    color: #aaaaaa;
                }
                &:hover {
                    &:before {
                        color: #ffffff;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .owl-controls {
            display: none;
        }
    }
    .stm_testimonials {
        &__review {
            position: relative;
            background: #fff;
            font-size: 16px;
            line-height: 34px;
            margin-bottom: 35px;
            padding: 79px 40px 80px;
            box-shadow: 0px 30px 50px 0px rgba(153, 153, 153, 0.15);
            &:before {
                @include stmicon('\10fc5b\10fc5b\10fc5b\10fc5b\10fc5b');
                font-size: 25px;
                letter-spacing: 6px;
                position: absolute;
                top: 30px;
                left: 39px;
            }
            &:after {
                @include stmicon('\10fff9');
                font-size: 20px;
                position: absolute;
                left: auto;
                top: auto;
                right: 30px;
                bottom: 30px;
            }
        }
        &__meta {
            display: flex;
            position: relative;

            &_left {
                padding-left: 0;
            }
            &_align-center {
                align-items: center;
            }
        }
        &__avatar {
            margin-right: 20px;
            img {
                border-radius: 50%;
                max-width: 100%;
                height: auto;
            }
            &_rounded {
                img {
                    border-radius: 50%;
                }
            }
            img {
                width: auto !important;
            }
        }
        &__info {
            margin-top: 10px;
            h5 {
                margin-bottom: 3px;
                text-transform: none !important;
            }
            h6 {
                text-transform: uppercase !important;
            }
            span {
                font-size: 16px;
                color: #333;
            }
        }
    }
}